import ObsClient from "./obs/esdk-obs-browserjs.3.22.3.min.js";
const ak = "JFQQK2T6H7CEU2KHDFFF";
const sk = "uczKVe0F1uREAnggbFpRVpypzjdnIDKXeG4jYRFd";
const server = "obs.cn-north-4.myhuaweicloud.com";
const bucket = "tuoyufuwu";
const isTest = false;
import { Toast, closeToast, showLoadingToast } from 'vant';
export const upload = (file, path, cb) => {
  const obsClient = new ObsClient({
    access_key_id: ak,
    secret_access_key: sk,
    server: server,
    timeout: 60 * 5,
  });

  return new Promise((resolve, reject) => {
    let key =
      "web/" +
      path +
      (isTest ? "/test/" : "/") +
      new Date().getTime() +
      "_" +
      file.name;
    obsClient.putObject(
      {
        Bucket: bucket,
        Key: key,
        SourceFile: file,
        ProgressCallback:
          cb ||
          ((e) => {
            // console.log(e);
          }),
      },
      function (err, result) {
        if (err) {
          reject("");
        } else {
          if (result.CommonMsg.Status < 300) {
            console.log("Create object successfully!");
            resolve("https://" + bucket + "." + server + "/" + key);
          } else {
            Toast('上传失败，请重新上传')
            console.log("Code-->", result.CommonMsg.Code);
            console.log("Message-->", result.CommonMsg.Message);
            reject("");
          }
        }
      }
    );
  });
};
